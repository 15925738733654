import React, {Component} from 'react';
import styled from 'styled-components';
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import {graphql} from "gatsby";
import get from 'lodash/get';
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { Container, Col, Row } from 'styled-bootstrap-grid';
import colors from '../styles/colors';
import fonts from '../styles/fonts';


const PageTitle = styled.h1`
    color: ${colors.grey};
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: ${colors.green};
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const ContainerStyled = styled(Container)`
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  padding-top: 45px;
  ${fonts.MontserratRegular};
  color: ${colors.grey};
  font-size: 13px;
  line-height: 23px;
  @media(min-width: 768px) {
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 55px;
  }
  h2 {
    color: ${colors.grey};
    ${fonts.CormorantGaramondLight};
    font-size: 40px;
    line-height: 48px;
    // max-width: 465px;
    padding-bottom: 20px;
    padding-top: 30px;
    @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
    }
  }
  h3 {
    ${fonts.CormorantGaramondLight};
    font-size: 30px;
    line-height: 38px;
    color: ${colors.black};
    padding-bottom: 10px;
    padding-top: 10px;
  }
  h3 {
    ${fonts.CormorantGaramondLight};
    font-size: 20px;
    line-height: 38px;
    color: ${colors.green};
    padding-bottom: 10px;
    padding-top: 10px;
  }
  a {
    ${fonts.MontserratMedium};
    color: ${colors.green};
    font-size: 13px;
    line-height: 23px;
    text-decoration: underline;
    transition: 0.2s;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        color: ${colors.green};
    }  
  }
  p {
    margin-bottom: 15px;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
`; 


class TextPageTemplate extends Component {
  render() {
    const path = this.props.location.pathname;
    const page = get(this, 'props.data.page');
    return (
      <Layout>
        <SEO title={page.title}
             description={page.metaDescription}
             path={path}/>
       <ContainerStyled>
         <Row>
           <Col>
              <PageTitle>{page.title}</PageTitle>
              <BorderLineDiv />
              {documentToReactComponents(page.content.json)}
           </Col>
         </Row>
       </ContainerStyled>
      </Layout>
    )
  }
}

export default TextPageTemplate;

export const pageQuery = graphql`
  query TextPageQuery($slug: String!) {
    page: contentfulTextPage(slug: {eq: $slug}) {
      id
      slug
      title
      metaDescription
      content {
        json
      }
    }
  }
`;
